import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Skeleton } from "antd";

const Contacts = lazy(() => import("./contacts/contacts"));
const Contact = lazy(() => import("./contacts/contact/contact"));
const Users = lazy(() => import("./users/users"));
const User = lazy(() => import("./users/user"));
const Bills = lazy(() => import("./comptabilite/bills/bills"));
const Proformas = lazy(() => import("./comptabilite/bills/proformas"));
const Articles = lazy(() => import("./comptabilite/bills/articles"));
const Payments = lazy(() => import("./comptabilite/payments/payments"));
const RegenCatalog = lazy(() => import("./regen/catalog/brands"));
const RegenFlux = lazy(() => import("./regen/flux/flux"));
const RegenStock=lazy(()=>import("./regen/stock/stock"))
const RepFlux = lazy(() => import("./rep/flux/flux"));
const RepStock=lazy(()=>import("./rep/stock/stock"))
const RepCatalog=lazy(()=>import("./rep/catalog/brands"))
export default function AppRoutes() {
  return (
    <Suspense fallback={<Skeleton active />}>
      <Switch>
        <Route path="/clients">
          <Contacts type="client" />
        </Route>
        <Route path="/fournisseurs">
          <Contacts type="fournisseur" />
        </Route>
        <Route path="/client/:_id">
          <Contact />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/user/:_id">
          <User />
        </Route>
        <Route path="/bills/sell">
          <Bills />
        </Route>
        <Route path="/bills/proforma">
          <Proformas />
        </Route>
        <Route path="/bills/articles">
          <Articles />
        </Route>
        <Route path="/payments">
          <Payments />
        </Route>
        <Route path="/regen/catalog">
          <RegenCatalog />
        </Route>
        <Route path="/regen/flux">
          <RegenFlux />
        </Route>
        <Route path="/regen/stock">
          <RegenStock />
        </Route>
        <Route path="/rep/flux">
          <RepFlux />
        </Route>
        
        
        {/*<Route path="/rep/lot/:_id">*/}
        {/*  <RepLot />*/}
        {/*</Route>*/}
        <Route path="/rep/catalog">
          <RepCatalog />
        </Route>
        <Route path="/rep/stock">
          <RepStock />
        </Route>
      </Switch>
    </Suspense>
  );
}