import React from "react";
import {Button, Layout, Menu, Typography} from "antd";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {getCurrentUser} from "../security/auth";
import {Link, useHistory} from "react-router-dom";
import {getSidemenu} from "../utils/routes";

const {Header, Content, Sider} = Layout;
const {Title} = Typography;
const {SubMenu} = Menu;

export default function DesktopLayout(props) {
  
  const {disconnect} = props;
  const user = getCurrentUser();
  const history = useHistory();
  function onMenuClick(params) {
    history.push(params.key);
  }
  
  return (
    <Layout>
      <Header
        style={{
          backgroundColor: "white",
          position: "fixed",
          zIndex: 2,
          width: "100%",
          boxShadow: "0px 3px 3px rgba(0,0,0,0.08)",
        }}
      >
        <Title style={{display: "inline-block", color: "black"}} level={4}>
          {user?.firstName + " " + user?.lastName}
        </Title>
        <div
          style={{
            display: "inline-block",
            lineHeight: "64px",
            float: "right",
            color: "#fff",
          }}
        >
          <Link to={"/user/"}>
            <Button type="link" size="large">
              <UserOutlined style={{color: "black"}}/>
            </Button>
          </Link>
          <Button type="link" onClick={disconnect} size="large">
            <LogoutOutlined style={{color: "black"}}/>
          </Button>
        </div>
      </Header>
      <Layout style={{color: "#fff"}} hasSider>
        <Sider
          theme="light"
          collapsible
          width={230}
          style={{
            overflow: "auto",
            paddingTop: 64,
            left: 0,
          }}
        >
          <Menu
            mode="inline"
            theme="light"
            defaultOpenKeys={["sub1"]}
            onClick={onMenuClick}
            style={{
              maxWidth: 230,
              position: "fixed",
              height: "100vh",
            }}
          >
            {user && getSidemenu(user?.role).map((item) =>
              item.type === "item" ? (
                <Menu.Item key={item.href}>
                  {item.icon}
                  <span>{item.text}</span>
                </Menu.Item>
              ) : (
                <SubMenu key={item.text} icon={item.icon} title={item.text}>
                  {item.items.map((i) =>
                    i.items ? (
                      <Menu.ItemGroup key={i.text} title={i.text}>
                        {i.items.map((it) => (
                          <Menu.Item key={it.href}>
                            <span>{it.text}</span>
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                    ) : (
                      <Menu.Item key={i.href}>
                        <span>{i.text}</span>
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              )
            )}
          </Menu>
        </Sider>
        <Layout style={{width: "100%"}}>
          <Content
            style={{
              backgroundColor: "white",
              marginTop: 64,
              height: "100%",
            }}
          >
            <div style={{padding: 10}}>{props.children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
