import React from "react";

import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/config";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./layout/login";
import Main from "./layout/main";
import moment from "moment";
moment.locale("fr-FR");

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route exact path='/login'>
            <Login />
          </Route>
          <Route path='/'>
            <Main />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
