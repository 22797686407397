const authenticate = (login, cb) => {
  localStorage.setItem("token", login.token);
  localStorage.setItem("user", JSON.stringify(login.user));
  cb();
};

const isAuthenticated = () => {
  return "token" in localStorage;
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const logout = (cb) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  cb();
};

export { authenticate, isAuthenticated, logout, getCurrentUser };
