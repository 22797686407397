import React, { useEffect } from "react";
import { Col, Grid, Row } from "antd";
import { getCurrentUser, isAuthenticated, logout } from "../security/auth";
import MobileLayout from "./mobile";
import DesktopLayout from "./desktop";
import { currentUser } from "../apollo/cache";
import { useHistory } from "react-router-dom";
import AppRoutes from "../components/appRoutes";

const { useBreakpoint } = Grid;

export default function MainLayout(props) {
  const history = useHistory();
  // const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const screens = useBreakpoint();
  currentUser(isAuthenticated() ? getCurrentUser() : {});

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push("/login");
    }
  }, []);

  function disconnect() {
    logout(() => {
      history.push("/login");
    });
  }

  return (
    <>
      {/* {!authenticated && <Spin spinning={!authenticated} />}
      {authenticated && ( */}
      <Row>
        <Col xs={24} sm={24}>
          {screens.xs && (
            <MobileLayout disconnect={disconnect}>
              <AppRoutes />
            </MobileLayout>
          )}
          {!screens.xs && (
            <DesktopLayout disconnect={disconnect}>
              <AppRoutes />
            </DesktopLayout>
          )}
        </Col>
      </Row>
      {/* )} */}
    </>
  );
}
