import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation login($credentials: CredentialsInput!) {
    login(credentials: $credentials) {
      token
      user {
        _id
        firstName
        lastName
        role
      }
    }
  }
`;

const SET_CURRENT_USER = gql`
  mutation setUserAuth($userAuth: UserAuthInput!) {
    setUserAuth(userAuth: $userAuth) @client {
      _id
      clientId
      name
      role
      priority
    }
  }
`;

export { LOGIN, SET_CURRENT_USER };
