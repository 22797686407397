import React, { useState } from "react";
import {
  Layout,
  Menu,
  Typography,
  Button,
  Drawer,
  Dropdown,
  Row,
  Col,
  Tag,
} from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  MoreOutlined,
  MenuOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { getCurrentUser } from "../security/auth";
import { getSidemenu } from "../utils/routes";
import { Link, useHistory } from "react-router-dom";
import { Offline } from "react-detect-offline";
const { Title } = Typography;
const { Header, Content } = Layout;

export default function MobileLayout(props) {

  const {  disconnect } = props;
  const user = getCurrentUser();
  const history = useHistory();

  const menu = (
    <Menu>
      <Menu.Item key={0}>
        <Link to={"/user/" + user._id}>
          <Button type='link' size='large' style={{ color: "black" }}>
            <UserOutlined />
            Profile
          </Button>
        </Link>
      </Menu.Item>
      <Menu.Item key={1}>
        <Button
          type='link'
          onClick={disconnect}
          size='large'
          style={{ color: "black" }}>
          <LogoutOutlined />
          Déconnexion
        </Button>
      </Menu.Item>
    </Menu>
  );
  const [visible, setvisible] = useState(false);
  function onCloseDrawer() {
    setvisible(false);
  }
  function onOpenDrawer() {
    setvisible(true);
  }

  function onMenuClick(params) {
    history.push(params.key);
    onCloseDrawer();
  }

  return (
    <Layout style={{ width: "100vw" }}>
      <Header
        style={{
          padding: 0,
          backgroundColor: "white",
          zIndex: 1,
          position: "fixed",
          boxShadow: "0px 3px 3px rgba(0,0,0,0.08)",
          width: "100%",
        }}>
        <Row align='middle'>
          <Col xs={3}>
            <Button
              type='link'
              size='large'
              onClick={onOpenDrawer}
              style={{ color: "black" }}>
              <MenuOutlined />
            </Button>
          </Col>
          <Col xs={18}>
            <Title
              style={{
                color: "black",
                paddingTop: 7,
                display: "inline-block",
                marginRight: 2,
              }}
              level={4}>
              {user ? user.name : ""}
            </Title>
            <Offline>
              <Tag icon={<LoadingOutlined />} color='#cd201f'>
                Hors Ligne
              </Tag>
            </Offline>
          </Col>
          <Col xs={3}>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              style={{ color: "#fff" }}>
              <Button type='link' size='large' style={{ color: "black" }}>
                <MoreOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          backgroundColor: "white",
          height: "100%",
          marginTop: 66,
          top: 0,
          left: 0,
        }}>
        {props.children}
        <Drawer
          width={230}
          title={user.name}
          placement={"left"}
          closable={false}
          onClose={onCloseDrawer}
          visible={visible}>
          <Menu theme='light' defaultOpenKeys={["sub1"]} onClick={onMenuClick}>
            {getSidemenu(user.role).map((item) => (
              <Menu.Item key={item.href}>
                {item.icon}
                <span>{item.text}</span>
              </Menu.Item>
            ))}
          </Menu>
        </Drawer>
      </Content>
    </Layout>
  );
}
