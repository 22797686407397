import React from "react";
import {
  ContactsOutlined,
  FileTextOutlined,
  HomeOutlined,
  SettingFilled,
  ThunderboltOutlined,
  ToolOutlined,
} from "@ant-design/icons";

function getSidemenu(type) {
  switch (type) {
    case "superAdmin":
    case "admin":
      return [
        {
          type: "item",
          text: "Acceuil",
          href: "/",
          icon: <HomeOutlined />,
        },
        {
          type: "menu",
          text: "Comptabilité",
          icon: <FileTextOutlined />,
          items: [
            {
              text: "Factures Proforma",
              href: "/bills/proforma",
            },
            {
              text: "Factures Vente",
              href: "/bills/sell",
            },
            {
              text: "Articles",
              href: "/bills/articles",
            },
            {
              text: "Paiements",
              href: "/payments",
            },
            // {
            //   text: "Bills Achat",
            //   href: "/facturea",
            // },
            // {
            //   text: "Commandes",
            //   href: "/commandes",
            // },
            // {
            //   text: "Charges",
            //   href: "/charges",
            // },
            // {
            //   text: "Paiements",
            //   href: "/paiements",
            // },
          ],
        },
        {
          type: "menu",
          text: "Regénération",
          icon: <ThunderboltOutlined />,
          items: [
            {
              text: "Flux",
              href: "/regen/flux",
            },
            {
              text: "Stock",
              href: "/regen/stock",
            },
            {
              text: "Catalogue",
              href: "/regen/catalog",
            },
          ],
        },
        {
          type: "menu",
          text: "Réparation",
          icon: <ToolOutlined />,
          items: [
            {
              text: "Flux",
              href: "/rep/flux",
            },
            {
              text: "Stock",
              href: "/rep/stock",
            },
            {
              text: "Catalogue",
              href: "/rep/catalog",
            },
          ],
        },
        {
          type: "menu",
          text: "Contacts",
          icon: <ContactsOutlined />,
          items: [
            {
              text: "Clients",
              href: "/clients",
            },
            {
              text: "Fournisseurs",
              href: "/fournisseurs",
            },
          ],
        },

        {
          type: "menu",
          text: "Administration",
          icon: <SettingFilled />,
          items: [
            {
              text: "Utilisateurs",
              href: "/users",
            },
            // {
            //   text: "Parametres",
            //   href: "/settings",
            // },
          ],
        },
        // {
        //
        //   icon: <TeamOutlined />,
        // },
        // {
        //   text: "Parametres",
        //   href: "/settings",
        //   icon: <SettingFilled />,
        // },
      ];
    case "stock":
      return [
        {
          type: "menu",
          text: "Regénération",
          icon: <ThunderboltOutlined />,
          items: [
            {
              text: "Flux",
              href: "/regen/flux",
            },
            {
              text: "Stock",
              href: "/regen/stock",
            },
            {
              text: "Catalogue",
              href: "/regen/catalog",
            },
          ],
        },
        {
          type: "menu",
          text: "Réparation",
          icon: <ToolOutlined />,
          items: [
            {
              text: "Flux",
              href: "/rep/flux",
            },
            {
              text: "Stock",
              href: "/rep/stock",
            },
            {
              text: "Catalogue",
              href: "/rep/catalog",
            },
          ],
        },
      ];
    default:
      break;
  }
}

function getMainPage(role) {
  switch (role) {
    case "superAdmin":
      return "/users";
    case "admin":
    case "client":
    case "chef":
      return "/webinars";
    default:
      return "/login";
  }
}

export { getSidemenu, getMainPage };
