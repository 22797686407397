import React, { useEffect } from "react";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import { LockOutlined, LoginOutlined, UserOutlined } from "@ant-design/icons";
import {
  authenticate,
  getCurrentUser,
  isAuthenticated,
} from "../security/auth";
import { LOGIN } from "../apollo/mutations/auth";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { currentUser } from "../apollo/cache";

function Login() {
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated()) {
      setupUser(getCurrentUser());
    }
  }, []);

  const [login] = useMutation(LOGIN, {
    onError: (error) => {
      message.error("Erreur d'authentification! " + error.message, 2);
    },
    onCompleted: (data) => {
      authenticate(data.login, () => {
        const { user } = data.login;
        setupUser(user);
        message.success("Bienvenu " + user.firstName, 2);
      });
    },
  });

  function setupUser(user) {
    currentUser({ ...user });
    //history.push(getMainPage(user.role));
    history.push("/");
  }

  function signin(value) {
    login({ variables: { credentials: { ...value } } });
  }

  return (
    <Form onFinish={signin}>
      <Form.Item
        rules={[
          {
            type: "email",
            message: "Veuillez saisir un e-mail valide!",
          },
          { required: true, message: "Veuillez saisir votre e-mail!" },
        ]}
        name="email"
      >
        <Input
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="E-mail"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "Veuillez saisir votre mot de passe!" },
        ]}
      >
        <Input
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          style={{
            background: "#5C5C5C",
            borderColor: "#5C5C5C",
          }}
        >
          Connexion
          <LoginOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
}

export default function LoginComp() {
  return (
    <Row justify="center">
      <Col xs={0} sm={24}>
        <Card
          style={{
            width: "30%",
            marginTop: "134px",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "30px",
            height: "100%",
          }}
        >
          {/* <div style={{ textAlign: "center", marginBottom: 15 }}>
            <img src='/static/logo.png' style={{ height: 80, width: 125 }} />
          </div> */}
          <Login />
        </Card>
      </Col>
      <Col xs={24} sm={0}>
        <div
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            padding: "30px",
            marginTop: "150px",
            height: "100%",
          }}
        >
          {/* <div style={{ textAlign: "center", marginBottom: 15 }}>
            <img src='/static/logo.png' style={{ height: 80, width: 125 }} />
          </div> */}
          <Login />
        </div>
      </Col>
    </Row>
  );
}
