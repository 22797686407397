import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
//cache init
const cache = new InMemoryCache();
// main link
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
  fetch,
});
// websocket subscription lin
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WEBSOCKET_URL,
  options: {
    reconnect: true,
  },
});
// split operation to adequate link (subscription/ operation)
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
//attaching JWT
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? "Bearer " + token.replace(/"/g, "") : "",
    },
  };
});
const client = new ApolloClient({
  link: link,
  cache,
});

export { client };
